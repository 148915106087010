import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import EventAPI from "../api/EventAPI"; // Import your EventAPI
import { formatDistanceToNow } from "date-fns";
import { GlobalContext } from "../context/GlobalContext";
import { ko } from "date-fns/locale"; // Import Korean locale (or use another locale)

function DateSessionPage() {
  const { id } = useParams(); // Get event ID from the URL
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null); // Track time left for each round
  const [currentRound, setCurrentRound] = useState(1); // Placeholder for the round info
  const [oppositeUser, setOppositeUser] = useState({
    name: "Ringo Y.", // Placeholder name
    description: "Black sweater blue jeans shaved head", // Placeholder description
  }); // Opposite user placeholder data
  const { globalState } = useContext(GlobalContext);

  useEffect(() => {
    // Start listening to event streaming
    const unsubscribe = EventAPI.getEventStreaming(id, (data) => {
      setEventData(data);
      setLoading(false);

      // Check if there's an active session and calculate time left for next round
      if (data.activeSession) {
        const nextRoundStartTime =
          data.activeSession.nextRoundStartedAt.toDate();
        const interval = setInterval(() => {
          const secondsLeft = Math.max(
            0,
            (nextRoundStartTime - new Date()) / 1000
          );
          setTimeLeft(
            secondsLeft > 0
              ? formatDistanceToNow(nextRoundStartTime, { locale: ko })
              : "Round has started!"
          );
        }, 1000);

        setCurrentRound(data.activeSession.currentRound || 1); // Get current round
        return () => clearInterval(interval); // Cleanup timer
      }
    });

    return () => unsubscribe(); // Clean up Firestore listener on unmount
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 h-screen flex flex-col justify-center items-center bg-[#fff5f7] text-center">
      <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
        <div className="flex justify-center items-center mb-4">
          <img src="/logo.png" alt="logo" className="h-12 mr-2" />
        </div>
        <h1 className="text-xl font-bold mb-2">Round {currentRound}/9</h1>
        <h2 className="text-lg mb-2">Find your next date!</h2>
        <div className="mb-4">
          <p className="text-2xl font-bold">{oppositeUser.name}</p>
          <p className="text-gray-500">{oppositeUser.description}</p>
        </div>
        {timeLeft !== 0 && timeLeft !== null && (
          <div className="bg-green-100 text-green-600 font-bold py-2 px-4 rounded-lg mb-4">
            <p>다음 데이트까지 남은 시간: {timeLeft}</p>
          </div>
        )}
        <div className="text-left">
          <h3 className="text-gray-600">Prompt:</h3>
          <p className="text-lg font-bold">
            What’s your favorite travel destination?
          </p>
        </div>
      </div>
      <div className="mt-4 text-pink-500 text-sm">
        {/* Support section */}
        {/* Need support? <a href="tel:+15035057808">Text +1 503-505-7808</a> */}
      </div>
    </div>
  );
}

export default DateSessionPage;
