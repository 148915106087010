// src/components/PrivateRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";

const PrivateRoute = ({ element: Component }) => {
  const { globalState } = useContext(GlobalContext);
  const { isAuthenticated } = globalState;

  return isAuthenticated ? <Component /> : <Navigate to="/profile" />;
};

export default PrivateRoute;
