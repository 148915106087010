import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DatePage from "../pages/DatePage";
import DateSessionPage from "../pages/DateSessionPage";

function Dates() {
  return (
    <Routes>
      <Route path="/:id" element={<DatePage />} />
      <Route path="/:id/session" element={<DateSessionPage />} />
    </Routes>
  );
}

export default Dates;
