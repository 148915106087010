import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AbstractChat from "../components/AbstractChat.js";
import MatchAPI from "../api/MatchAPI";
import MatchPage from "../pages/MatchPage.js";

function Matches() {
  return (
    <Routes>
      <Route path="/" element={<MatchPage />} />
      <Route
        path="/chat"
        element={
          <AbstractChat
            getChat={MatchAPI.getMatchChat}
            addMessageToChat={MatchAPI.addMessageToChat}
            chatNode="Chat"
          />
        }
      />
    </Routes>
  );
}

export default Matches;
