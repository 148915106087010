import React from "react";

const Modal = ({ onClose, onConfirm, title, yesText, noText }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8">
        <p className="mb-6">{title}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onConfirm}
            className="bg-pink-500 text-white px-4 py-2 rounded"
          >
            {yesText}
            {/* 네, 도착했어요! */}
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded"
          >
            {/* 가는 중이에요. */}
            {noText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
