import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlobalProvider } from "./context/GlobalContext";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <GlobalProvider>
    <App />
  </GlobalProvider>
);
