// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// If you're using Firebase Storage, import it as well
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBTjmEx_eBvM320xwSU3SpgJMIOc1fhFe8",
  authDomain: "spiin-51969.firebaseapp.com",
  projectId: "spiin-51969",
  storageBucket: "spiin-51969.appspot.com",
  messagingSenderId: "969743322259",
  appId: "1:969743322259:web:361c613e214c3c1e7cd166",
  measurementId: "G-9QV5ECSYE8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); // If using storage
export const analytics = getAnalytics(app);
export default app;
