import React from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      {/* Navigation - Logo fixed on the top left */}
      <div className="fixed top-4 left-4">
        <img src="logo.png" alt="Logo" className="h-10" />
      </div>

      {/* Main Content - Hero Section */}
      <div className="flex flex-col items-center text-center mt-16">
        <h1 className="text-5xl font-bold text-gray-800 mb-4">
          데이팅 앱이 지겹다면<br></br>
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-orange-500">
            스핀으로 만나요!
          </span>
        </h1>
        <p className="text-gray-500 text-lg mb-6">
          새로운 만남을 시작하는 새로운 방법
          <br />
          지금 바로 시작해보세요!
        </p>
        <button
          className="bg-black text-white px-6 py-3 rounded-full text-lg font-semibold shadow-md hover:bg-gray-900"
          onClick={() => navigate("/events")}
        >
          진행중인 스피드 데이팅 이벤트
        </button>

        {/* Image Section */}
        <div className="mt-12">
          {/* <img
            src="/path-to-your-uploaded-image.png" // Replace with your actual image path
            alt="Event Preview"
            className="w-full max-w-lg object-contain"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
