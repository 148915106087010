import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BottomNavBar from "./components/BottomNavBar";

// Import your page components
import EventsPage from "./pages/EventsPage";
import MyEventsPage from "./pages/MyEventsPage";
import ProfilePage from "./pages/ProfilePage";
import PrivateRoute from "./components/PrivateRoute";
import EventShowPage from "./pages/EventShowPage";
import CheckoutPage from "./pages/CheckoutPage";
import LandingPage from "./pages/LandingPage";
import Matches from "./matches/Matches";
import Dates from "./dates/Dates";

function App() {
  const isDateRoute = window.location.pathname.startsWith("/dates");

  // Extract routes into a reusable function
  const renderRoutes = () => (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/events" element={<EventsPage />} />
      <Route
        path="/my-events"
        element={<PrivateRoute element={MyEventsPage} />}
      />
      <Route
        path="/my-events/:eventId"
        element={<EventShowPage myEvent={true} />}
      />
      <Route path="/matches/*" element={<Matches />} />
      <Route path="/dates/*" element={<Dates />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/events/:eventId" element={<EventShowPage />} />
      <Route
        path="/checkout/:participantType/:eventId"
        element={<PrivateRoute element={CheckoutPage} />}
      />
    </Routes>
  );

  return (
    <Router>
      <div className="min-h-screen bg-[#fefcff]">
        {/* Desktop layout */}
        <div className="hidden lg:flex justify-center items-center h-screen px-4">
          {/* Logo or any other content */}
          <div className="w-1/2 flex justify-center items-center">
            <img src="/logo.png" alt="Logo" className="max-w-xs" />
          </div>

          {/* Mobile app container */}
          <div className="w-1/2 h-full max-w-md bg-white shadow-lg flex flex-col">
            <div className="flex-grow overflow-auto">
              {renderRoutes()} {/* Render routes for desktop */}
            </div>
            {!isDateRoute && <BottomNavBar />}{" "}
            {/* Conditionally render BottomNavBar */}
          </div>
        </div>

        {/* Mobile layout */}
        <div className="lg:hidden flex flex-col h-screen">
          <div className="flex-grow overflow-auto">
            {renderRoutes()} {/* Render routes for mobile */}
          </div>
          {!isDateRoute && <BottomNavBar />}{" "}
          {/* Conditionally render BottomNavBar */}
        </div>
      </div>
    </Router>
  );
}

export default App;
