import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import EventAPI from "../api/EventAPI"; // Import your EventAPI
import { formatDistanceToNow } from "date-fns";
import Modal from "../components/Modal"; // Assuming you have a Modal component
import { GlobalContext } from "../context/GlobalContext";
import { arrayUnion } from "firebase/firestore";
import { ko } from "date-fns/locale"; // Import Korean locale (or use another locale)

function DatePage() {
  const { id } = useParams(); // Get event ID from the URL
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [checkedIn, setCheckedIn] = useState(false); // Track check-in status
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [eventExpired, setEventExpired] = useState(false); // Check if event is expired
  const { globalState } = useContext(GlobalContext);
  const navigate = useNavigate(); // Add useNavigate hook to redirect user

  useEffect(() => {
    let interval;

    // Start listening to event streaming
    const unsubscribe = EventAPI.getEventStreaming(id, (data) => {
      setEventData(data);
      setLoading(false);

      // Check if event start time is in the past
      const eventStartTime = data.date.toDate();
      const currentDate = new Date();
      const eventDateOnly = new Date(
        eventStartTime.getFullYear(),
        eventStartTime.getMonth(),
        eventStartTime.getDate()
      );
      const currentDateOnly = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      // Compare only the date part
      if (eventDateOnly < currentDateOnly) {
        setEventExpired(true);
        return;
      }

      // Calculate time left until the event starts
      interval = setInterval(() => {
        const secondsLeft = Math.max(0, (eventStartTime - new Date()) / 1000);
        console.log(checkedIn, secondsLeft);

        if (secondsLeft <= 0 && checkedIn) {
          clearInterval(interval); // Clean up the interval before navigating
          navigate(`/dates/${id}/session`); // Navigate to session page when event starts
          navigate(0);
        }

        setTimeLeft(
          secondsLeft > 0
            ? formatDistanceToNow(eventStartTime, { locale: ko })
            : 0
        );
      }, 1000);
    });

    return () => {
      if (interval) clearInterval(interval); // Clean up the interval on unmount
      unsubscribe(); // Clean up Firestore listener on unmount
    };
  }, [id, navigate, checkedIn]);

  useEffect(() => {
    // Check if the user is already checked in
    if (eventData && globalState.user && globalState.user.userID) {
      setCheckedIn(eventData.checkedInUsers.includes(globalState.user.userID));
    }
  }, [eventData, globalState.user]);

  const handleCheckIn = () => {
    setShowModal(true); // Show the modal for check-in confirmation
  };
  const handleModalClose = () => {
    setShowModal(false); // Just close the modal without checking in
  };

  const handleModalConfirm = async () => {
    setCheckedIn(true); // Set checked in status to true
    setShowModal(false); // Close the modal

    // Update the event with the checked-in user
    try {
      await EventAPI.updateEvent(id, {
        checkedInUsers: arrayUnion(globalState.user.userID), // Add userID to the checkedInUsers array
      });
    } catch (error) {
      console.error("Error updating event after check-in:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (eventExpired) {
    return (
      <div className="p-6">
        <h1 className="text-2xl font-bold">{eventData.name}</h1>
        <p className="text-red-500">이 이벤트는 더이상 유효하지 않습니다.</p>
      </div>
    );
  }

  // If the user is not checked in, show the first page
  if (!checkedIn) {
    return (
      <div className="p-6 h-screen flex flex-col justify-center items-center bg-[#fff5f7] text-center">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-center items-center mb-4">
            <img src="/logo.png" alt="logo" className="h-12 mr-2" />
          </div>
          <h1 className="text-2xl font-bold">{eventData.name}</h1>
          <p className="text-gray-600">{eventData.description}</p>
          <p className="mt-4">
            이벤트 시작 시간: {eventData.date.toDate().toLocaleString()}
          </p>

          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
            onClick={handleCheckIn}
          >
            이벤트 장소에 도착했어요!
          </button>

          {/* Modal for check-in confirmation */}
          {showModal && (
            <Modal
              title="이벤트 장소에 도착했어요!"
              yesText="네, 도착했어요!"
              noText="가는 중이에요."
              onClose={handleModalClose}
              onConfirm={handleModalConfirm}
            />
          )}
        </div>
        <div className="mt-4 text-pink-500 text-sm">
          {/* Need support? <a href="tel:+15035057808">Text +1 503-505-7808</a> */}
        </div>
      </div>
    );
  }

  // If the user is checked in, show the thank-you page with the correct styling
  return (
    <div className="p-6 h-screen flex flex-col justify-center items-center bg-[#fff5f7] text-center">
      <p className="text-gray-500 mb-4">
        {/* Please leave your phone on with this page open during the event */}
        이벤트 중에는 이 페이지를 열어두세요.
      </p>
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center mb-4">
          <img src="/logo.png" alt="logo" className="h-12 mr-2" />
        </div>
        <h1 className="text-2xl font-bold mb-2">체크인이 완료되었습니다.</h1>
        <h1 className="text-2xl font-bold mb-4">
          좋은 시간이 되길 바랍니다! 🎉
        </h1>
        {timeLeft !== 0 && timeLeft !== null && (
          <div className="bg-yellow-100 text-yellow-600 font-bold py-2 px-4 rounded-lg mb-4">
            <p>이벤트 시작까지 남은 시간: {timeLeft}</p>
          </div>
        )}
      </div>
      <div className="mt-4 text-pink-500 text-sm">
        {/* Need support? <a href="tel:+15035057808">Text +1 503-505-7808</a> */}
      </div>
    </div>
  );
}

export default DatePage;
