import React, { useEffect, useState, useContext } from "react";
import Utility from "../utils/utility";
import { useNavigate } from "react-router-dom";
import EventsAPI from "../api/EventAPI";
import { GlobalContext } from "../context/GlobalContext";

const MyEventsPage = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const handleEventClick = (item) => {
    navigate(`/my-events/${item.id}`, { state: { event: item } });
  };

  const [showLoading, setShowLoading] = useState(true);
  const { globalState } = useContext(GlobalContext);

  const fetchEvents = async () => {
    try {
      const eventsData = await EventsAPI.getEventsFor(
        globalState.user.userID,
        globalState.user.gender
      );
      console.log("Fetched events:", eventsData);

      setEvents(eventsData);
      setShowLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    if (globalState.user) fetchEvents();
  }, [globalState.user]);

  if (showLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  return (
    <div className="container mx-auto my-6 px-4">
      <h1 className="text-xl font-bold my-6 px-4">나의 이벤트</h1>

      <div className="list">
        {events && events.length
          ? events.map((item) => {
              return (
                <DatesItem
                  key={item.id}
                  item={item}
                  hasActiveSession={item.activeSession?.at && !item.isCompleted}
                  onClick={(_) => handleEventClick(item)}
                />
              );
            })
          : !showLoading && (
              <div className="no-items text-center text-gray-500">
                아직 참여하는 이벤트가 없습니다.
              </div>
            )}
      </div>
    </div>
  );
};

export default MyEventsPage;

function DatesItem({ item, onClick, hasActiveSession }) {
  if (item == undefined) return null;

  return (
    <div
      className="flex items-center py-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 transition-all"
      onClick={onClick}
    >
      <img
        src={item.photos[0] || "/default-event.jpg"}
        alt={item.name}
        className="w-20 h-20 rounded-lg object-cover mr-4"
      />
      <div className="flex flex-col flex-grow">
        <div className="text-gray-500 text-sm flex justify-between items-center">
          <span>{Utility.formatDate(item.date)}</span>

          {hasActiveSession && (
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
            </span>
          )}
        </div>
        <div className="text-md font-bold text-gray-900">{item.name}</div>
        <div className="text-gray-600 text-sm">
          {item.hostname} ({item.ageRange})
        </div>
      </div>
    </div>
  );
}
