import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronLeftIcon, MapPinIcon } from "@heroicons/react/24/outline";

const EventShowPage = ({ myEvent = false }) => {
  const { eventId } = useParams(); // Get the event ID from the URL
  const [event, setEvent] = useState(null);
  const [showBottomSheet, setShowBottomSheet] = useState(false); // Control bottom sheet visibility
  const navigate = useNavigate(); // Use navigate to handle back button

  useEffect(() => {
    const fetchEvent = async () => {
      const docRef = doc(db, "events", eventId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setEvent(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchEvent();
  }, [eventId]);

  if (!event) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="h-16 w-16 border-4 border-gray-300 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }
  const {
    name,
    date,
    address,
    description,
    hostname,
    photos = [],
    ageRange,
    ticketPrice = 20000, // Assuming price is 20000 KRW
  } = event;

  const openBottomSheet = () => {
    setShowBottomSheet(true);
  };

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
  };

  return (
    <div className="event-show-page relative bg-white min-h-screen ">
      <div className="relative w-full max-w-md mx-auto">
        <button
          onClick={() => navigate(-1)} // Go back to the previous page
          className="absolute top-4 left-4 z-10 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-full p-2"
          aria-label="Go back"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
      </div>
      {photos.length > 0 && (
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className="w-full h-auto"
          containerClass="w-full"
          responsive={{
            desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
            tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
            mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
          }}
          showDots
          swipeable
        >
          {photos.map((photoUrl, index) => (
            <div key={index} className="w-full">
              <img
                src={photoUrl}
                alt={`Event photo ${index + 1}`}
                className="w-full h-96 object-cover"
              />
            </div>
          ))}
        </Carousel>
      )}

      {/* Event details */}
      <div className="p-6">
        {/* Event Name */}
        <h1 className="text-3xl font-bold text-gray-900 mb-2">{name}</h1>

        {/* Host and Time */}
        <div className="flex items-center text-gray-500 mb-4">
          <span className="mr-2">주최: {hostname}</span>
          <span className="ml-auto">
            {new Date(date.seconds * 1000).toLocaleDateString("ko-KR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </div>

        {/* Event Location */}
        <div className="flex items-center mb-4 text-gray-700">
          <MapPinIcon className="h-6 w-6 mr-2 text-purple-600" />
          {address}
        </div>

        {/* Additional Event Details */}
        <div className="flex flex-wrap gap-2 text-sm text-gray-600 mb-4">
          <span className="px-2 py-1 bg-gray-100 rounded">
            연령대: {ageRange}
          </span>
        </div>

        {/* Ticket Purchase Section */}
        {!myEvent && (
          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <h2 className="text-lg font-semibold mb-2">티켓 구매</h2>
            <p className="text-gray-500 mb-4">
              이벤트에 참가하려면 아래에서 티켓을 구매해 주세요.
            </p>
            <div className="flex items-center justify-between mb-4">
              <span className="text-xl font-bold text-gray-900">
                {ticketPrice.toLocaleString()}원
              </span>
              <button
                className="bg-purple-600 text-white text-lg px-6 py-2 rounded-lg shadow-md hover:bg-purple-700"
                onClick={openBottomSheet} // Open bottom sheet on click
              >
                티켓 구매
              </button>
            </div>
          </div>
        )}

        {/* Event Description */}
        <div className="text-gray-700">
          <h2 className="text-lg font-semibold mb-2">이벤트 설명</h2>
          <p>{description}</p>
        </div>
      </div>

      {/* Bottom Sheet */}
      {showBottomSheet && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="absolute bottom-0 w-full bg-white rounded-t-lg p-6 transition-transform transform translate-y-0 z-50">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold text-gray-900">티켓 선택</h3>
              <button
                onClick={closeBottomSheet}
                className="text-gray-500 hover:text-gray-900"
              >
                닫기
              </button>
            </div>
            <div className="flex flex-col space-y-4">
              <button
                className="bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600"
                onClick={() =>
                  navigate(`/checkout/men/${eventId}`, {
                    state: { ticketPrice: event.ticketPrice },
                  })
                }
              >
                남성 참가자 티켓 구매
              </button>
              <button
                className="bg-pink-500 text-white py-3 rounded-lg hover:bg-pink-600"
                onClick={() =>
                  navigate(`/checkout/women/${eventId}`, {
                    state: { ticketPrice: event.ticketPrice },
                  })
                }
              >
                여성 참가자 티켓 구매
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventShowPage;
