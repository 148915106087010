// src/components/PhoneAuth.js
import React, { useState } from "react";
import { auth } from "../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const PhoneAuth = ({ onVerificationSuccess }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [step, setStep] = useState(1); // Track the current step
  const [error, setError] = useState("");

  // Initialize reCAPTCHA
  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        document.getElementById("recaptcha-container"),
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber
            console.log("reCAPTCHA solved!");
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            console.log("reCAPTCHA expired!");
          },
        }
      );
    }
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    setError("");
    if (!phoneNumber) {
      setError("전화번호를 입력해주세요.");
      return;
    }

    // Ensure phone number is in E.164 format
    let formattedPhoneNumber = phoneNumber;
    if (!formattedPhoneNumber.startsWith("+")) {
      formattedPhoneNumber = "+1" + phoneNumber;
    }

    setUpRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        setStep(2); // Move to the verification code entry step
      })
      .catch((error) => {
        console.error("Error during signInWithPhoneNumber", error);
        setError("인증 코드 전송 중 오류가 발생했습니다. 다시 시도해주세요.");
        if (window.recaptchaVerifier) {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        }
      });
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    setError("");

    if (!verificationCode) {
      setError("인증 코드를 입력해주세요.");
      return;
    }

    if (confirmationResult == null) return;

    confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("User signed in:", user);
        // Notify parent component of successful verification
        if (onVerificationSuccess) {
          onVerificationSuccess(user);
        }
      })
      .catch((error) => {
        console.error("Error while verifying OTP", error);
        setError("인증 코드가 올바르지 않습니다. 다시 확인해주세요.");
      });
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      {step === 1 && (
        <form onSubmit={onSignInSubmit} className="w-full max-w-sm">
          <h3 className="text-xl mb-4 text-center">전화번호 입력</h3>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="전화번호 입력 (예: 1012345678)"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <button
            id="sign-in-button"
            type="submit"
            className="w-full bg-primary p-2 rounded"
          >
            다음
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
          {/* Invisible reCAPTCHA */}
          <div id="recaptcha-container"></div>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={onSubmitOTP} className="w-full max-w-sm">
          <h3 className="text-xl mb-4 text-center">인증 코드 입력</h3>
          <input
            type="number"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="6자리 인증 코드 입력"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <button type="submit" className="w-full bg-primary p-2 rounded">
            확인
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
      )}
    </div>
  );
};

export default PhoneAuth;
