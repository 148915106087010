// src/components/BottomNavBar.js
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  UserIcon,
  HeartIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { GlobalContext } from "../context/GlobalContext"; // Import GlobalContext

const BottomNavBar = () => {
  const [active, setActive] = useState("이벤트");
  const navigate = useNavigate();
  const { globalState } = useContext(GlobalContext); // Access globalState
  const { isAuthenticated } = globalState;

  const menuItems = isAuthenticated
    ? [
        { name: "이벤트", icon: CalendarIcon, path: "/events" },
        { name: "나의 이벤트", icon: HomeIcon, path: "/my-events" },
        { name: "매치", icon: HeartIcon, path: "/matches" },
        { name: "프로필", icon: UserIcon, path: "/profile" },
      ]
    : [
        { name: "이벤트", icon: CalendarIcon, path: "/events" },
        { name: "프로필", icon: UserIcon, path: "/profile" },
      ];

  return (
    <nav className="bg-white shadow-lg fixed bottom-0 w-full z-50">
      <div className="flex justify-around py-2">
        {menuItems.map((item) => (
          <button
            key={item.name}
            onClick={() => {
              setActive(item.name);
              navigate(item.path);
            }}
            className={`flex flex-col items-center ${
              active === item.name ? "text-primary" : "text-gray-500"
            }`}
          >
            <item.icon className="h-6 w-6" />
            <span className="text-xs">{item.name}</span>
          </button>
        ))}
      </div>
    </nav>
  );
};

export default BottomNavBar;
