// src/context/GlobalContext.js
import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase"; // Ensure these are correctly exported
import { doc, getDoc, setDoc } from "firebase/firestore";
import UserAPI from "../api/UserAPI"; // Import your UserAPI

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    user: null,
    isAuthenticated: false,
    loading: true,
    // Add more global state variables as needed
  });

  useEffect(() => {
    // Check localStorage for cached user data
    const lsUser = localStorage.getItem("user");
    if (lsUser && lsUser !== "undefined") {
      const user = JSON.parse(lsUser);
      setGlobalState((prevState) => ({
        ...prevState,
        user,
        isAuthenticated: true,
        loading: false,
      }));
    }

    // Set up Firebase auth listener
    const unsubscribe = onAuthStateChanged(auth, async (fbUser) => {
      if (fbUser) {
        // User is signed in
        try {
          // Check if user data is already in localStorage
          const lsUser = localStorage.getItem("user");
          let userData;

          if (lsUser && lsUser !== "undefined") {
            const cachedUser = JSON.parse(lsUser);
            if (cachedUser.userID === fbUser.uid) {
              userData = cachedUser;
              // Optionally, refresh user data in the background
              UserAPI.getUserByID(fbUser.uid).then((latestUserData) => {
                if (latestUserData) {
                  localStorage.setItem("user", JSON.stringify(latestUserData));
                  setGlobalState((prevState) => ({
                    ...prevState,
                    user: latestUserData,
                  }));
                }
              });
            } else {
              // Fetch user data from Firestore
              userData = await UserAPI.getUserByID(fbUser.uid);
              if (userData) {
                localStorage.setItem("user", JSON.stringify(userData));
              } else {
                // If user data doesn't exist, create a new user document
                userData = {
                  userID: fbUser.uid,
                  phoneNumber: fbUser.phoneNumber,
                  // Add other default fields as needed
                };
                await UserAPI.createUser(userData);
                localStorage.setItem("user", JSON.stringify(userData));
              }
            }
          } else {
            // Fetch user data from Firestore
            userData = await UserAPI.getUserByID(fbUser.uid);
            if (userData) {
              localStorage.setItem("user", JSON.stringify(userData));
            } else {
              // If user data doesn't exist, create a new user document
              userData = {
                userID: fbUser.uid,
                phoneNumber: fbUser.phoneNumber,
                // Add other default fields as needed
              };
              await UserAPI.createUser(userData);
              localStorage.setItem("user", JSON.stringify(userData));
            }
          }

          setGlobalState((prevState) => ({
            ...prevState,
            user: userData,
            isAuthenticated: true,
            loading: false,
          }));
        } catch (error) {
          console.error("Error fetching user data:", error);
          // Handle error, possibly sign out the user
          auth.signOut();
          localStorage.removeItem("user");
          setGlobalState((prevState) => ({
            ...prevState,
            user: null,
            isAuthenticated: false,
            loading: false,
          }));
        }
      } else {
        // User is signed out
        localStorage.removeItem("user");
        setGlobalState((prevState) => ({
          ...prevState,
          user: null,
          isAuthenticated: false,
          loading: false,
        }));
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
