import React, { useEffect, useState } from "react";
import EventAPI from "../api/EventAPI";
import { Link } from "react-router-dom";

const EventsPage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getEvents = async () => {
      const fetchedEvents = await EventAPI.fetchEvents();
      console.log("Fetched events:", fetchedEvents);
      setEvents(fetchedEvents);
    };

    getEvents();
  }, []);

  return (
    <div className="events-page bg-white">
      <h1 className="text-xl font-bold my-6 px-4">진행중인 이벤트</h1>

      {/* Wrapper for mobile scroll view */}
      <div className="flex flex-col space-y-6 px-4 pb-8">
        {events.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </div>
    </div>
  );
};

// Component to render each event card
const EventCard = ({ event }) => {
  const {
    id,
    name,
    date,
    address,
    description,
    hostname,
    photos = [],
    coordinates,
    ageRange,
  } = event;

  return (
    <Link to={`/events/${id}`}>
      <div className="event-card overflow-hidden">
        {/* Display event photo (first one in the array) */}
        {photos.length > 0 && (
          <div className="event-photo h-56 md:h-72 w-full">
            <img
              src={photos[0]}
              alt={`${name} event`}
              className="w-full h-full object-cover rounded-md"
            />
          </div>
        )}

        {/* Event details */}
        <div className="py-3 px-2">
          <h2 className="text-2xl font-bold mb-2 text-gray-800">{name}</h2>
          <p className="text-sm text-gray-500 mb-4">
            {new Date(date.seconds * 1000).toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          <p className="text-lg font-semibold text-gray-700 mb-4">{address}</p>

          {/* Additional event details */}
          <div className="flex flex-wrap gap-2 text-sm text-gray-600 mb-4">
            <span className="px-2 py-1 bg-gray-100 rounded">
              Age Range: {ageRange}
            </span>
            <span className="px-2 py-1 bg-gray-100 rounded">
              Host: {hostname}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventsPage;
