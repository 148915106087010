import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
import PhoneAuth from "../components/PhoneAuth";
import { auth, storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import UserAPI from "../api/UserAPI";
import { signOut } from "firebase/auth";
import { deleteObject } from "firebase/storage"; // Import deleteObject

const ProfilePage = () => {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { user, isAuthenticated, loading } = globalState;
  const [needsProfileInfo, setNeedsProfileInfo] = useState(false);
  const [name, setName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [error, setError] = useState("");
  const [gender, setGender] = useState("");
  useEffect(() => {
    if (!loading && isAuthenticated) {
      if (!user.name || !user.profilePhotoURL) {
        setNeedsProfileInfo(true);
      } else {
        setName(user.name);
        setProfilePhoto(user.profilePhotoURL);
        setGender(user.gender);
      }
    }
  }, [loading, isAuthenticated, user]);

  const handleVerificationSuccess = async (fbUser) => {
    try {
      let userData = await UserAPI.getUserByID(fbUser.uid);
      if (userData) {
        localStorage.setItem("user", JSON.stringify(userData));
        setGlobalState((prevState) => ({
          ...prevState,
          user: userData,
          isAuthenticated: true,
          loading: false,
        }));
        if (!userData.name || !userData.profilePhotoURL) {
          setNeedsProfileInfo(true);
        }
      } else {
        userData = {
          userID: fbUser.uid,
          phoneNumber: fbUser.phoneNumber,
        };
        await UserAPI.createUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        setGlobalState((prevState) => ({
          ...prevState,
          user: userData,
          isAuthenticated: true,
          loading: false,
        }));
        setNeedsProfileInfo(true);
      }
    } catch (error) {
      console.error("Error handling verification success:", error);
      alert("인증 후 사용자 데이터를 가져오는 중 오류가 발생했습니다.");
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setUploading(true);

    if (!name || !gender) {
      setError("이름과 성별을 입력해주세요.");
      setUploading(false);
      return;
    }

    try {
      let profilePhotoURL = user.profilePhotoURL || null;
      if (profilePhoto) {
        const storageRef = ref(
          storage,
          `users/${user.userID}/profilePhoto/photo.jpg`
        );
        await uploadBytes(storageRef, profilePhoto);
        profilePhotoURL = await getDownloadURL(storageRef);
        setUploading(false);
      }

      const updatedUserData = {
        ...user,
        name,
        profilePhotoURL,
        gender,
      };
      await UserAPI.updateUser(user.userID, updatedUserData);

      localStorage.setItem("user", JSON.stringify(updatedUserData));
      setGlobalState((prevState) => ({
        ...prevState,
        user: updatedUserData,
      }));
      setNeedsProfileInfo(false);
      setIsEditingName(false);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("프로필 업데이트 중 오류가 발생했습니다.");
      setUploading(false);
    }
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true); // Start uploading and show the spinner
      try {
        // Step 1: Delete the existing profile photo if it exists
        if (user.profilePhotoURL) {
          const oldPhotoRef = ref(storage, user.profilePhotoURL);
          if (oldPhotoRef) {
            try {
              await deleteObject(oldPhotoRef);
            } catch (error) {
              console.error("Error deleting old profile photo:", error);
            }
          }
        }

        // Step 2: Upload the new profile photo
        const storageRef = ref(
          storage,
          `users/${user.userID}/profilePhoto/photo.jpg`
        );
        await uploadBytes(storageRef, file); // Upload the file to Firebase Storage
        const photoURL = await getDownloadURL(storageRef); // Get the new photo URL

        // Step 3: Update the Firestore document with the new profile photo URL
        const updatedUserData = { ...user, profilePhotoURL: photoURL };
        await UserAPI.updateUser(user.userID, updatedUserData); // Update the user profile in Firestore

        // Step 4: Update the global state and local storage
        setGlobalState((prevState) => ({
          ...prevState,
          user: updatedUserData,
        }));
        localStorage.setItem("user", JSON.stringify(updatedUserData));

        setProfilePhoto(photoURL); // Update profile photo after upload completes
      } catch (error) {
        console.error("Error uploading profile photo:", error);
        alert("프로필 사진 업로드 중 오류가 발생했습니다.");
      } finally {
        setUploading(false); // Hide the spinner when the upload is done
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");
      setGlobalState((prevState) => ({
        ...prevState,
        user: null,
        isAuthenticated: false,
      }));
    } catch (error) {
      console.error("Error signing out:", error);
      alert("로그아웃 중 오류가 발생했습니다.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <PhoneAuth onVerificationSuccess={handleVerificationSuccess} />;
  }

  if (needsProfileInfo) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
        <form onSubmit={handleProfileSubmit} className="w-full max-w-sm">
          <h3 className="text-xl mb-4 text-center">프로필 정보 입력</h3>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="이름"
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          {/* Gender Selection Dropdown */}
          <div className="mb-4">
            <label
              htmlFor="gender"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              성별
            </label>
            <select
              id="gender"
              name="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">성별을 선택하세요</option>
              <option value="Male">남성</option>
              <option value="Female">여성</option>
            </select>
          </div>

          <input
            type="file"
            accept="image/*"
            onChange={(e) => setProfilePhoto(e.target.files[0])}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <button
            type="submit"
            className="w-full bg-primary p-2 rounded"
            disabled={uploading}
          >
            {uploading ? "업로드 중..." : "프로필 저장"}
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)]">
      <div className="relative">
        <img
          src={profilePhoto || "/default-profile.png"}
          alt="Profile"
          className="h-40 w-40 rounded-full object-cover shadow-md"
        />
        {uploading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full z-10">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <label
          htmlFor="photo-upload"
          className="absolute inset-0 flex items-end justify-center bg-black bg-opacity-50 text-white rounded-full cursor-pointer"
          style={{ bottom: 0 }}
        >
          <span className="pb-2">Edit</span>
        </label>
        <input
          id="photo-upload"
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handlePhotoUpload}
        />
      </div>
      <div className="text-center mt-6">
        <div className="flex items-center justify-center mb-4">
          {isEditingName ? (
            <form onSubmit={handleProfileSubmit} className="flex items-center">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border rounded-md px-2 py-1 text-lg"
              />
              <button
                type="submit"
                className="ml-2 text-blue-500 hover:text-blue-700"
              >
                저장
              </button>
            </form>
          ) : (
            <div className="flex items-center">
              <h2 className="text-2xl font-bold text-gray-900">{name}</h2>
              <button
                className="ml-2 text-sm text-gray-500 hover:text-gray-800"
                onClick={() => setIsEditingName(true)}
              >
                ✏️
              </button>
            </div>
          )}
        </div>

        <p className="text-gray-500 mb-6">{user.phoneNumber}</p>

        <button
          onClick={handleLogout}
          className="border border-red-400 text-red-400 py-2 px-6 rounded-md hover:bg-red-50"
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default ProfilePage;
