import React, { useState, useEffect, useContext, useRef } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useLocation, useParams } from "react-router-dom";
import Utility from "../utils/utility";
import { GlobalContext } from "../context/GlobalContext";
import useGoBack from "../utils/useGoBack";

const AbstractChat = ({ getChat, addMessageToChat, lastChecked, params }) => {
  const chatNode = "Chat";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  const location = useLocation();
  const { match } = location.state || {};
  let { eventID, userType, sitters, movers } = useParams();
  if (params?.eventID) {
    eventID = params.eventID;
    userType = params.userType;
    // sitters = params.sitters;
    // movers = params.movers;
  }

  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [title, setTitle] = useState();
  const [chatID, setChatID] = useState();
  const [chatNodeWithPrefix, setChatNodeWithPrefix] = useState();

  useEffect(() => {
    setGlobalState((prevState) => ({ ...prevState, showNav: false }));

    return () => {
      if (!params?.eventID) {
        setGlobalState((prevState) => ({ ...prevState, showNav: true }));
      }
    };
  }, [setGlobalState]);

  const updateChat = (error, data) => {
    if (error) {
      console.error(error);
      return;
    }
    if (data[chatNodeWithPrefix]) {
      setMessages(data[chatNodeWithPrefix]);
    }
  };

  const goBack = useGoBack();

  useEffect(() => {
    if (chatID && chatNodeWithPrefix) {
      const unsubscribe = getChat(
        chatID,
        chatNodeWithPrefix,
        updateChat,
        eventID ? "events" : undefined
      );
      if (lastChecked && globalState.user)
        lastChecked(chatID, globalState.user?.userID);
      return () => {
        if (unsubscribe) {
          unsubscribe.unsubDoc();
          unsubscribe.unsubNested();
        }
      };
    }
  }, [chatID, eventID, chatNodeWithPrefix, globalState.user]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (match && match.user && match.user.name) {
      setTitle(match.user.name.split(" ")[0]);
      setChatID(match.id);
      setChatNodeWithPrefix(chatNode);
    }
    if (eventID && userType) {
      let groupName = userType === Utility.userType.sitters ? sitters : movers;
      groupName === "undefined" && (groupName = null);
      let t = `${groupName || userType} Chat`;
      setTitle(t);
      setChatID(eventID);
      setChatNodeWithPrefix(userType + chatNode);
    }
  }, [match, eventID, userType]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      setInput("");

      const msg = {
        message: input,
        userID: globalState.user.userID,
        name: globalState.user.name,
      };
      await addMessageToChat(
        chatID,
        chatNodeWithPrefix,
        msg,
        eventID ? "events" : undefined
      );
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-4rem)]">
      {/* Header */}
      <div className="flex items-center bg-white p-4 border-b shadow">
        <button
          className={`mr-2 ${params?.eventID ? "invisible" : ""}`}
          onClick={goBack}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
        </button>
        <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
      </div>

      {/* Chat Messages */}
      <div className="flex-grow overflow-y-auto p-4 bg-gray-50">
        {messages.map((message, index) => (
          <ChatMessage
            key={message.id}
            index={index}
            message={message}
            globalState={globalState}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Form */}
      <form
        className="flex items-center p-4 bg-white border-t shadow"
        onSubmit={sendMessage}
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="메시지를 입력하세요..."
          className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <button
          type="submit"
          className="ml-2 bg-primary text-black px-4 py-2 rounded-lg hover:bg-primary-dark"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default AbstractChat;

function ChatMessage({ index, message, globalState }) {
  const isYou = () => {
    return message.userID === globalState.user.userID;
  };

  return (
    <div
      className={`flex flex-col ${isYou() ? "items-end" : "items-start"} mb-4`}
    >
      <div
        className={`rounded-lg p-3 ${
          isYou() ? "bg-blue-400 text-white" : "bg-gray-200 text-gray-800"
        } max-w-xs`}
      >
        {message.message}
      </div>
      <div className="text-xs text-gray-400 mt-1">
        {Utility.formatDate(message.timestamp)}
      </div>
    </div>
  );
}
