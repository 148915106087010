// src/api/UserAPI.js
import { db, storage } from "../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
class UserAPI {
  static collectionName = "users"; // Collection name

  // Get user by ID
  static async getUserByID(userID) {
    try {
      const userDocRef = doc(db, UserAPI.collectionName, userID);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        return { userID: userDocSnap.id, ...userDocSnap.data() };
      } else {
        // User does not exist
        return null;
      }
    } catch (error) {
      console.error("Error getting user by ID:", error);
      throw error;
    }
  }

  // Create a new user
  static async createUser(userData) {
    try {
      const userDocRef = doc(db, UserAPI.collectionName, userData.userID);
      await setDoc(userDocRef, userData);
      return userData;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }

  // Update user data
  static async updateUser(userID, updatedData) {
    try {
      const userDocRef = doc(db, UserAPI.collectionName, userID);
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
  }

  static async uploadUserProfilePhoto(userID, file) {
    const filePath = `users/${userID}/profilePhoto/photo.jpg`;
    const storageRef = ref(storage, filePath);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  }

  // Add more methods as needed
}

export default UserAPI;
