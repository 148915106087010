import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import MatchAPI from "../api/MatchAPI";
import { Link } from "react-router-dom";

export default function MatchPage() {
  const { globalState } = useContext(GlobalContext);
  const [matches, setMatches] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const unsub = MatchAPI.fetchMatches(
      globalState.user,
      (matchesList, hasUser) => {
        setMatches(matchesList);
        hasUser && setShowLoading(false);
      }
    );

    return () => {
      if (unsub) unsub();
    };
  }, [globalState.user]);

  if (showLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  console.log(matches);
  return (
    <div className="bg-white my-6 px-4">
      <h1 className="text-xl font-bold mb-4">나의 매치</h1>

      {matches.length === 0 ? (
        <div className="flex flex-col justify-center items-center min-h-screen text-center">
          <p className="text-gray-500 mb-2">아직 매칭된 사용자가 없습니다.</p>
          <p className="text-gray-500 mb-4">더 많은 이벤트에 참여해보세요!</p>
          <button
            className="bg-primary text-black rounded p-2"
            onClick={() => (window.location.href = "/events")}
          >
            모든 이벤트 보기
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          {matches.map((match, index) => (
            <Link
              to="/matches/chat"
              state={{ match }}
              key={match?.id}
              className="match-item"
            >
              <div
                key={index}
                className="flex items-center space-x-4 border-b pb-4"
              >
                <img
                  src={match.user.profilePhotoURL || "/default-profile.png"}
                  alt={`${match.user.name}'s profile`}
                  className="h-12 w-12 rounded-full object-cover"
                />
                <div className="flex-1">
                  <h2 className="text-lg font-semibold">{match.user.name}</h2>
                  {match.message ? (
                    <p className="text-gray-500">{match.message}</p>
                  ) : (
                    <p className="text-gray-500">새로운 매치 💕</p>
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
