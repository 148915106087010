import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import EventsAPI from "../api/EventAPI";
import { GlobalContext } from "../context/GlobalContext";
const CheckoutPage = () => {
  const navigate = useNavigate(); // To navigate back to the event show page
  const { eventId, participantType } = useParams(); // Get event ID and participant type from URL
  const location = useLocation();
  const { ticketPrice } = location.state || {};

  const { globalState, setGlobalState } = React.useContext(GlobalContext);
  const closeCheckout = () => {
    navigate(-1); // Go back to the event show page
  };

  const onJoin = async (type) => {
    const user = await EventsAPI.addUserToEvent(
      eventId,
      globalState.user.userID,
      type
    );
    if (user) {
      setGlobalState((prev) => ({ ...prev, user }));

      navigate(`/my-events/${eventId}`); // Redirect to the user's event page
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-white">
      {/* Close button on the top-right */}
      <button
        onClick={closeCheckout}
        className="absolute top-4 right-4 bg-gray-300 hover:bg-gray-400 text-gray-700 rounded-full p-2 z-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      {/* Checkout content */}
      <div className="flex flex-col items-center justify-center h-full px-6 py-8">
        <h1 className="text-2xl font-bold mb-6">
          참여하기 - {participantType === "men" ? "남성 참가자" : "여성 참가자"}
        </h1>
        <p className="text-lg text-gray-600 mb-4">결제 금액: {ticketPrice}원</p>

        <button
          onClick={() => onJoin(participantType)}
          className="bg-primary text-black px-4 py-2 rounded-lg hover:bg-primary-dark"
        >
          참여하기
        </button>

        {/* Placeholder for further checkout form or content */}
        <p className="text-lg">여기에서 결제 페이지를 완성할 수 있습니다.</p>
      </div>
    </div>
  );
};

export default CheckoutPage;
